import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Embark on your KOPE journey by registering yourself and your organization.`}</p>
    </PageDescription>
    <p>{`To get started with KOPE, you need to register yourself and your organization.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/1_register-organisation-button.png",
      "alt": "1_register-organisation-button.png"
    }}></img>
    <p>{`Complete the required fields and click `}<inlineCode parentName="p">{`Register.`}</inlineCode></p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/2_register-organisation-form.png",
      "alt": "2_register-organisation-form.png"
    }}></img>
    <p>{`With your Organization ready to go, you need to add a user.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/3_register-organisation-register-user.png",
      "alt": "3_register-organisation-register-user.png"
    }}></img>
    <p>{`With your Organization and User now registered, you can begin to solve Offsite construction.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      